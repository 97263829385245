.App {
}

ul {
  padding-left: 0;
  list-style-type: none;
}

ul li {
  line-height: 2;
}

.Screen {
  margin: 0 auto;
  padding: 0 2rem 2rem 2rem;
  max-width: 36rem;
}

.Button {
  background: white;
  border-radius: 0.25em;
  border: 1px solid #e0e0e0;
  min-height: 3.2em;
  padding: 0.75em;
}

.ButtonGroup {
  margin-top: 2rem;
}

.ButtonGroup > .Button + .Button {
  margin-left: 1rem;
}

.TextButton {
  background: none;
  padding: 0;
  border: none;
  font-size: 1rem;
  color: blue;
  text-decoration: underline;
}